import React, { memo, useEffect, useState } from 'react';
import Select from 'react-select';

import { STATUSES, IMPORT_STATUSES, PRODUCT_STATUSES, DOCUMENT_UPLOAD_STATUSES, PRODUCT_STATUS_OPTIONS } from '../../../lib/constants/fields';

/** Styles */
import './Td.css';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { convertDataToOptions, createDefaultOption } from '../../../services/util/dataHandlerService';

const Td = ({ index, field, value, to = '', onClick, moduleName, item, notClickable, isListing, selectData, brands, saveChange, submitForm, editItem }) => {
  if (field === 'commission_percentage' || field === 'margin_percentage') {
    value += '%';
  }
  const[show, setShow] = useState(false)

  useEffect(() => {
    if(!editItem){
      setShow(false)
    }
  }, [editItem])

  const showInputField = () => {
    setShow(!show)
    saveChange({index, value: true, field: 'edit'})
  }
  
  const types = [
      { label: 'EMS', value: 1 },
      { label: 'APS', value: 2 }
  ]

  const dropdownFields = {
    manufacturer: 'manufacturers',
    brand: 'brands',
    status: 'status',
    type: 'productTypes',
    packaging_unit: 'packagingUnits'
  }


  if (field === 'status' || field === 'advertising_status') {
    let badge = PRODUCT_STATUSES[value];
    if (moduleName === 'importJobs') {
      badge = IMPORT_STATUSES[value];
    }
    if( moduleName === 'DocumentUploads'){
      badge = DOCUMENT_UPLOAD_STATUSES[value];

    }
    return (
      <td>
      
        {!show && <span className='cursor-pointer' onDoubleClick={() => showInputField()}>{badge}</span> }
        { show && dropdownFields[field] && (
          <div className="custom_select" style={{minWidth: '100px'}}>
            <Select
              options={convertDataToOptions(PRODUCT_STATUS_OPTIONS)}
              defaultValue={createDefaultOption(PRODUCT_STATUS_OPTIONS.find(x => x.id == value))}
              onChange={(e) => saveChange({index, value: e.value, field: 'status'})}
              menuPortalTarget={document.body}
            />
          </div>
        ) }

        { show && (
          <Actions save={() => {
            submitForm(index)
            setShow(false)
          }} close={() => saveChange({index, value: false, field: "edit"})} />   
        ) }
      </td>
    );
  }

  if (field === 'type' && moduleName == 'manufacturers') {
    return <td>{types.find(x => x.value == value).label}</td>;
  }

  switch (typeof value) {
    case 'boolean':
      value = value ? 'active' : 'disabled';
      break;

    case 'string':
      if(isListing && field === 'marketplace_name'){
        let img = 'de'
        if(value.split('.').length > 1){
          img = value.split('.')[1]
        }
        value = <li className="_li" key={value}> 
            <img className='flag' src={`/assets/images/flags/${img}.svg`} alt='' />
            {value} 
          </li>;

      }else if (field === 'data') {
        value = Object.keys(JSON.parse(value)[0]).map(item => {
          return <li key={item}> {item} </li>;
        });

        value = <ul>{value}</ul>;
      }
      break;

    case 'object':
      if (value) {
        console.log('never ');
        value = Object.keys(value).map((child, i) => {
          if (child !== '__typename' && i === 1) {
            return (
              <p key={i}>
                <strong className="text-capitalize">{child} :</strong> {value[child]}
              </p>
            );
          } else {
            return null;
          }
        });
      }

      break;

    default:
      break;
  }

  const isLink = field === 'name';
  const pageArr = ['manufacturers', 'products', 'listings'];
  const itemUrl = id => {
    if(pageArr.includes(moduleName)){
      if(isListing){
        return `/listings/${item.slug}/edit`;
      }
     return `/${moduleName}/${item.slug}/edit`;
    } else if (moduleName !== 'importJobs') {
     return `/${moduleName}/${id}/edit`;
    } else {
     return `/products/import/${id}`;
    }
  };


  if(selectData){
    selectData['brands'] = brands
  }

  return (
    <td className={`${notClickable ? '' : 'cursor-pointer'} ${isLink ? 'td-link' : ''}`} onClick={onClick}>
      {notClickable ? (
        field === 'embeddedLocation' ? (
          <Link target="_blank" style={{ color: '#00488c' }} to={'/' + value} title={value}><span>{value}</span></Link>
        ) : (
          <>
            {!show && <span title={value} onDoubleClick={() => showInputField(field) } style={{ width: '100%', display: 'block' }}>{value??''}</span> }
            { show && dropdownFields[field] && (
              <div className="custom_select" style={{minWidth: '120px', position: 'relative'}}>
                <Select
                  id={field}
                  name={field}
                  options={convertDataToOptions(selectData[dropdownFields[field]])}
                  defaultValue={createDefaultOption(selectData[dropdownFields[field]].find(x => x.name == value))}
                  onChange={(e) => saveChange({index, value: e.label, field})}
                  menuPortalTarget={document.body}
                />             
              </div>
            ) }
            { show && !dropdownFields[field] && <input type="text" id={field} className='form-control' defaultValue={value} onChange={(e) => {
              value = e.target.value
              saveChange({index, value: e.target.value, field})
            }} /> }

            { show && (
                <Actions save={() => {
                  submitForm(index)
                  setShow(false)
                }} close={() => saveChange({index, value: false, field: "edit"})} />   
            ) }
          </>
        )
      ) : (
        <Link style={{color: 'inherit', display: 'block'}} to={itemUrl(item.id || item.product_parent_id)} title={value}>
          {value}
        </Link>
      )}
    </td>
  );
};


const Actions = ({save, close}) => {
  return (
    <div className="action_buttons">
      <div className="action_btn save_btn" onClick={save}>
        <i class="fas fa-check"></i>
      </div>
      <div className="action_btn close_btn" onClick={close}>
        <i class="fas fa-times"></i>
      </div>
    </div>
  )
}

export default memo(Td);
