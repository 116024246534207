import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useSelector } from 'react-redux';

import Table from '../../components/table/Table';

import { getProductSettings, getProducts as QUERY } from '../../services/api/queryService';
import { queryStringHandler, fetchData } from '../../services/util/dataHandlerService';
import Routes from '../../lib/constants/routes';

const description = {
  module: 'products',
  description: 'Products',
  req: 'searchProducts',
  url: Routes.products.index,
  tab: 'search',
  is_product: true
};

const ProductSearchTab = ({ canDelete, location}) => {
  const { user_type, manufacturers } = useSelector(state => state.auth.user);
  const [ activeManufacturers, setActiveManufacturers] = useState([]);
  const { first, page, keyword, filter } = queryStringHandler(location.search);
  const [roleFilter, setRoleFilter] = useState(''); 

  useEffect(() => {
    const storageActiveManufacturers = JSON.parse(localStorage.getItem('active_manufacturers'));
    if(storageActiveManufacturers){
      setActiveManufacturers(storageActiveManufacturers);
    }
  }, []);


  window.addEventListener('activeManufacturerChange', (e) => {
    setActiveManufacturers(JSON.parse(localStorage.getItem('active_manufacturers')));
  }); 

  useEffect(() => { 
    if(user_type === 1){
        setRoleFilter(manufacturers.map(id => `manufacturer_id[]=${id}`).join('&'))
      }
    if(user_type === 2 && activeManufacturers){
        setRoleFilter(activeManufacturers.filter(x => parseInt(x.id)).map(aManu => `manufacturer_id[]=${aManu.id}`).join('&'))
      }
  }, [activeManufacturers]);

  let { loading, data, refetch, error } = useQuery(QUERY, {
    variables: {
      first,
      page,
      input: keyword,
      order: 'product_parent_id',
      filter: filter ? `${filter}&${roleFilter}` : roleFilter
    },
    fetchPolicy: 'no-cache'
  });

  const { data: productSettingsData } = useQuery(getProductSettings);
  let selectData;

  if (productSettingsData) {
    selectData = JSON.parse(productSettingsData.productSettings);
    selectData.marketplaces.forEach(item => {
      if (typeof item.tax_rate === 'string') {
        item.tax_rate = JSON.parse(item.tax_rate);
      }
    })
    selectData.marketplaces = selectData.marketplaces.filter(x => x.status == 1);
  }

  useEffect(() => {
    if(productSettingsData && activeManufacturers) {
      if(activeManufacturers.length > 0){
        selectData.manufacturers = activeManufacturers;
      }
    }
  }, [activeManufacturers, selectData]);

  if (error) {
    throw new Error(error);
  }

  console.log(selectData)

  return (
    <>
      {!data || !selectData ? (
        <div className="spinner absolute">Spinner</div>
      ) : (
        <Table
          openNewTab={true}
          loading={loading}
          refetch={refetch}
          canDelete={canDelete}
          description={description}
          pagerData={{
            ...fetchData(description.req, data).pagerData,
            search: keyword,
            queryStrFilter: filter,
            tab: description.tab
          }}
          tableData={fetchData(description.req, data).data}
          expandedHeader={true}
          hasExport={true}
          filter={filter}
          selectData={selectData}
        />
      )}
    </>
  );
};

export default ProductSearchTab;
